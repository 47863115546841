import { QsHttpService } from '../core/http/qs-http.service';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { IDataPayloadMultiple, IDataPayloadSingle } from '../core/http/http.interface';
import { IContent, IContentCategory, IContentView } from './content.interface';
import { ITopic } from '../components/sections/topics-section/topics-section.interface';
import { ESortOrder } from '../components/search-bar/search-bar.interface';

@Injectable({ providedIn: 'root' })
export class ContentService {
  protected readonly _http: QsHttpService = inject(QsHttpService);

  public getContents(
    offset: number = 0,
    limit: number = 12,
    sortOrder?: ESortOrder,
  ): Observable<IDataPayloadMultiple<IContent>> {
    const params = [
      'populate=coverImage',
      `pagination[offset]=${offset}`,
      `pagination[limit]=${limit}`,
    ];

    sortOrder === ESortOrder.FEATURED || !sortOrder
      ? params.push('sort[0]=catalogImportance:asc', 'sort[1]=title:asc')
      : params.push(`sort[0]=title:${sortOrder}`);

    return this._http.get<IDataPayloadMultiple<IContent>>(`/contents/all?${params.join('&')}`);
  }

  public getContentsByCategoryAndSlug(
    offset: number = 0,
    limit: number = 12,
    sortOrder?: ESortOrder,
    category?: string,
    slug?: string,
  ): Observable<IDataPayloadMultiple<IContent>> {
    const params = [
      `pagination[offset]=${offset}`,
      `pagination[limit]=${limit}`,
    ];

    sortOrder === ESortOrder.FEATURED || !sortOrder
      ? params.push('sort[0]=catalogImportance:asc', 'sort[1]=title:asc')
      : params.push(`sort[0]=title:${sortOrder}`);

    return this._http.get<IDataPayloadMultiple<IContent>>(`/${category}/${slug}?${params.join('&')}`);
  }

  public getContentsByCategoryTopicAndSlug(
    offset: number = 0,
    limit: number = 12,
    sortOrder?: ESortOrder,
    category?: string,
    categorySlug?: string,
    topicSlug?: string,
  ): Observable<IDataPayloadMultiple<IContent>> {
    const params = [
      `pagination[offset]=${offset}`,
      `pagination[limit]=${limit}`,
    ];

    sortOrder === ESortOrder.FEATURED || !sortOrder
      ? params.push('sort[0]=catalogImportance:asc', 'sort[1]=title:asc')
      : params.push(`sort[0]=title:${sortOrder}`);

    return this._http.get<IDataPayloadMultiple<IContent>>(`/${category}/${categorySlug}/topic/${topicSlug}?${params.join('&')}`);
  }

  public getContent(slug: string): Observable<IContent> {
    const params = [
      `filters[slug][$eq]=${slug}`,
      'populate=seo,seo.metaImage,coverImage,spiralCover,outreaches.coverImage,outreaches.format,format,redirect_to,related_product_tags.contents.coverImage',
    ];

    return this._http.get<IDataPayloadMultiple<IContent>>(`/contents/?${params.join('&')}`)
      .pipe(
        map((response) => {
          if (response.data && response.data.length > 0) {
            return response.data[0].attributes as unknown as IContent;
          }

          return {} as unknown as IContent;
        }),
      );
  }

  public getContentViewPage(): Observable<IDataPayloadSingle<IContentView>> {
    const params = [
      'populate=personalizationSectionFormats.image'
    ];

    return this._http.get<IDataPayloadSingle<IContentView>>(`/content-view-page?${params.join('&')}`);
  }

  public getContenCategorytViewPage(category: 'military' | 'industry', slug: string): Observable<IDataPayloadSingle<IContentCategory>> {
    const pluralMap = {
      military: 'militaries',
      industry: 'industries',
    };

    const params = [
      `filters[slug][$eq]=${slug}`,
      'populate=featuredTitles.coverImage,seo.metaImage,ctas',
    ];

    return this._http.get<IDataPayloadSingle<IContentCategory>>(`/${pluralMap[category]}?${params.join('&')}`)
  }

  public getTopics(category: 'industry' | 'military', categorySlug: string): Observable<IDataPayloadMultiple<ITopic>> {
    const topic = category === 'industry'
      ? 'topics'
      : 'military_topics';

    const params = [
      'populate=*',
      'pagination[pageSize]=999',
    ];

    return this._http.get<IDataPayloadMultiple<any>>(`/${topic}/${categorySlug}?${params.join('&')}`);
  }
}
